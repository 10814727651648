import CampoTexto from "../../../Campos/CampoTexto";
import Seletor from "../../../Seletor";
import { Grid } from "@mui/material";
import { Skeleton } from "@mui/material";

export default function EnderecoExterior({
  setData,
  isLoading,
  enderecoDefaultValue,
}) {
  const handleChange = (e) => {
    let newEndereco = {
      ...enderecoDefaultValue,
      [e.target.name]: e.target.value,
    };

    setData(newEndereco);
  };

  const handleChangeSelected = (e) => {
    let newEndereco = enderecoDefaultValue;
    newEndereco.idPais = e;
    setData(newEndereco);
  };

  return (
    <Grid
      container
      xs={12}
      rowSpacing={2}
      columnSpacing={{ xs: 1, sm: 1, md: 2 }}
    >
      <Grid item xs={6}>
        {isLoading ? (
          <Skeleton animation="wave" height={60} />
        ) : (
          <CampoTexto
            handlerChange={handleChange}
            name="logradouro"
            fullWidth={true}
            size="medium"
            value={enderecoDefaultValue.logradouro}
            label="Logradouro"
            enable={true}
            defaulValue="Logradouro"
          />
        )}
      </Grid>
      <Grid item xs={6}>
        {isLoading ? (
          <Skeleton animation="wave" height={60} />
        ) : (
          <CampoTexto
            handlerChange={handleChange}
            name="numero"
            fullWidth={true}
            size="medium"
            value={enderecoDefaultValue.numero}
            label="Número"
            enable={true}
            defaulValue="Número"
          />
        )}
      </Grid>
      <Grid item xs={6}>
        {isLoading ? (
          <Skeleton animation="wave" height={60} />
        ) : (
          <CampoTexto
            fullWidth={true}
            handlerChange={handleChange}
            name="cidade"
            size="medium"
            value={enderecoDefaultValue.cidade}
            label="Cidade"
            defaulValue="Cidade"
            enable={true}
          />
        )}
      </Grid>
      {enderecoDefaultValue.paises && (
        <Grid item xs={6}>
          {isLoading ? (
            <Skeleton animation="wave" height={60} />
          ) : (
            <Seletor
              isLoading={isLoading}
              setSelect={(e) => handleChangeSelected(e)}
              // sx={{ mt: 2 }}
              list={enderecoDefaultValue.paises}
              size="medium"
              key={"pais-endereco"}
              label="Pais"
              fullWidth={true}
              value={enderecoDefaultValue?.idPais}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
}
