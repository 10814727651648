import { IVariable } from "../../interfaces/IVariable";
import { createTheme, Theme } from "@mui/material/styles";
import { darkenColor } from "../../util/helpers";
import { getlocalStorageIdHotsite } from "../../util/LocalStorage";

let idHotsiteLocalStorage = getlocalStorageIdHotsite();

export const variables: IVariable = {
  IdHotsite:idHotsiteLocalStorage > 0 ? idHotsiteLocalStorage :  9,
  Loja: "Pride One",
  UserStorage: "SgT1mjN2K0C6K4WXuSiDjQ==",
  UserToken: "DF+PIA6Mbk28NhHs60SXpg==",
  UrlApi: "https://prd-ecommerce-duo-api.primecase.com.br/api",
  UrlLoja:"https://prd-pdo-ljc-web.primecase.com.br/",
};

export const template: Theme = createTheme({
  palette: {
    primary: {
      main: "#e67e22",
      light: "#e67e22",
      //lighter: "#ece6f7",
      dark: darkenColor("#e67e22", 10), // Escurece em 20%
    },
    secondary: {
      main: "#292d2f",
    },
    error: {
      main: "#a91c1c",
    },
    text: {
      primary: "#222",
      //light: "#fff",
    },
    warning: {
      main: "#ff9800",
    },
    // white: {
    //   main: "#fff",
    // },
  },

  typography: {
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontSize: 14,
    },
  },
});
