import { useState, useEffect } from "react";
import { getSocio, editaSocio } from "../../../api/Cadastro";
import SocioForm from "./SocioForm";
import { Skeleton } from "@mui/material";

export default function SocioItem({
  idCliente,
  idPerfilLimiteOperacional,
  setDada,
  isLoading,
}) {
  const [dadosForm, setDadosForm] = useState(null);
  const [defaultDadosForm, setDefaultDadosForm] = useState({});
  const [idTipoEstadoCivil, setIdTipoEstadoCivil] = useState(0);

  const [idTipoSocio, setIdTipoSocio] = useState(0);
  const [idNacionalidade, setIdNacionalidade] = useState(0);

  useEffect(() => {
    init(idTipoEstadoCivil, idTipoSocio, idNacionalidade);
  }, []);

  const getSocioCampos = async (
    idTipoEstadoCivil,
    idTipoSocio,
    idNacionalidade
  ) => {
    const response = await getSocio(
      idCliente,
      idTipoEstadoCivil,
      idPerfilLimiteOperacional,
      idTipoSocio,
      idNacionalidade
    );

    return response;
  };

  const editaCampoSocio = async (
    idTipoEstadoCivil,
    idTipoSocio,
    idNacionalidade,
    idSocio,
    array,
    index
  ) => {
    let response = await editaSocio(
      idCliente,
      idTipoEstadoCivil,
      idPerfilLimiteOperacional,
      idTipoSocio,
      idNacionalidade,
      idSocio
    );

    // Verifica se o response retornou dados válidos
    if (response) {
      // Mapeia os campos do response e atualiza os valores no array baseado no index
      let campos = response.campos.map((item) => {
        let filterCampos = array[index].campos.filter(
          (i) => i.label === item.label
        );
        if (filterCampos.length > 0) {
          item.valor = filterCampos[0].valor;
        }
        return item;
      });

      // Atualiza os campos do sócio no array com base no index
      array[index].campos = campos;

      console.log("campos", ...array);

      // Atualiza os estados com o array atualizado
      setDadosForm(() => [...array]);
      setDada(() => [...array]);
    } else {
      console.error("Response vazio ou inválido", response);
    }

    return;
  };

  const buscaCamposNovoSocio = async (
    idTipoEstadoCivil,
    idTipoSocio,
    idNacionalidade,
    isBuscaUltimoDado = false
  ) => {
    let response = await getSocioCampos(
      idTipoEstadoCivil,
      idTipoSocio,
      idNacionalidade
    );

    if (response.length <= 0) {
      console.error("Não foi encontrado nenhum campo para socio!");
      return;
    }

    return response.at(-1);
  };

  const init = async (
    idTipoEstadoCivil,
    idTipoSocioparams,
    idNacionalidade,
    array,
    index
  ) => {
    let response = await getSocioCampos(
      idTipoEstadoCivil,
      idTipoSocioparams ?? idTipoSocio,
      idNacionalidade
    );

    if (array?.length > 0) {
      response[index].assinaturaContratoSocio =
        array[index].assinaturaContratoSocio;
      if (
        response[index]?.campos[0]?.valor === array[index]?.campos[0]?.valor &&
        response.length === array.length
      ) {
        array[index] = response[index];
        setDadosForm(() => [...array]);
        setDada(() => [...array]);
        return;
      }
      const responseFilter = response.find(
        (i) => i.idTipoSocio === array[index].idTipoSocio
      );
      let campos = responseFilter.campos.map((item) => {
        let filterCampos = array[index].campos.filter(
          (i) => i.label === item.label
        );
        console.log("filterCampos", filterCampos);
        if (filterCampos.length > 0) {
          item.valor = filterCampos[0].valor;
        }
        return item;
      });
      array[index].campos = campos;
      setDadosForm(() => [...array]);
      setDada(() => [...array]);
      return;
    } else {
      if (response.length > 0) {
        let nome = response[0]?.campos?.filter(
          (item) => item.propriedade === "CpfCnpj"
        );
        let cpfCnpj = response[0]?.campos?.filter(
          (item) => item.propriedade === "Nome"
        );

        if (!!nome[0]?.valor || cpfCnpj[0]?.valor) {
          setDefaultDadosForm(() => ({ ...response[0] }));
          setDadosForm(() => [...response]);
          setDada(() => response);
          return;
        }
      }

      setDefaultDadosForm(() => ({ ...response[0] }));
      setDadosForm([]);
      setDada([]);
    }
  };

  return (
    <>
      {isLoading ? (
        <>
          {Array.from({ length: 2 }, () => (
            <Skeleton animation="wave" height={80} />
          ))}
        </>
      ) : (
        <SocioForm
          identificaItem="Sócio"
          dadosForm={dadosForm}
          setDadosForm={setDadosForm}
          setData={setDada}
          defaultDadosForm={defaultDadosForm}
          title="Sócio(s)"
          tooltipAdd="Adicione um sócio"
          tooltipDelete="Remove o sócio selecionado"
          initBuscaCampos={init}
          buscaCamposNovoSocio={buscaCamposNovoSocio}
          idTipoEstadoCivil={idTipoEstadoCivil}
          idTipoSocio={idTipoSocio}
          idNacionalidade={idNacionalidade}
          editaSocio={editaCampoSocio}
          setIdTipoSocio={setIdTipoSocio}
          setIdTipoEstadoCivil={setIdTipoEstadoCivil}
          setIdNacionalidade={setIdNacionalidade}
        />
      )}
    </>
  );
}
