import { IVariable } from "../../interfaces/IVariable";
import { createTheme, Theme } from "@mui/material/styles";
import { darkenColor } from "../../util/helpers";
import { getlocalStorageIdHotsite } from "../../util/LocalStorage";


// UrlLoja: "https://hlg-ecommerce-par-api.primecase.com.br/api",

let idHotsiteLocalStorage = getlocalStorageIdHotsite();

export const variables: IVariable = {
  IdHotsite: idHotsiteLocalStorage,
  Loja: "dgcambio",
  UserStorage: "DKI+gFZA+EG1PRp56JMXXQ==",
  UserToken: "TxES27YavEajDyF9pgI1Hg==",
  UrlApi: "https://dev-ecommerce-dgc-api.primecase.com.br/api",
  UrlLoja:"https://dev-ecommerce-dgc-web.primecase.com.br",
};

export const template: Theme = createTheme({
  palette: {
    primary: {
      main: "#000",
      light: "#fff",
      //lighter: "#ece6f7",
      dark: darkenColor("#000", 10), // Escurece em 20%
    },
    secondary: {
      main: "#fff",
    },
    error: {
      main: "#a91c1c",
    },
    text: {
      primary: "#222",
      //light: "#fff",
    },
    warning: {
      main: "#ff9800",
    },
    // white: {
    //   main: "#fff",
    // },
  },

  typography: {
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontSize: 14,
    },
  },
});
