import { IVariable } from "../../interfaces/IVariable";
import { createTheme, Theme } from "@mui/material/styles";
import { getlocalStorageIdHotsite } from "../../util/LocalStorage";

let idHotsiteLocalStorage = getlocalStorageIdHotsite();


export const variables: IVariable = {
  IdHotsite: idHotsiteLocalStorage,
  Loja: "sendmoney",
  UserStorage: "35qp8Er/4U6JHb3acBVbNQ==",
  UserToken: "eSYzpH+kIEGXJK+MfurdNQ==",
  UrlApi: "https://prd-ecommerce-snd-api.primecase.com.br/api",
  UrlLoja:""
};

export const template: Theme = createTheme({
  palette: {
    primary: {
      main: "#004c71",
      light: "#B799F2",
      //lighter: "#ece6f7",
      dark: "#023046",
    },
    secondary: {
      main: "#316787",
    },
    error: {
      main: "#a91c1c",
    },
    text: {
      primary: "#222",
      //light: "#fff",
    },
    warning: {
      main: "#ff9800",
    },
    // white: {
    //   main: "#fff",
    // },
  },

  typography: {
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontSize: 14,
    },
  },
});
