import { IVariable } from "../../interfaces/IVariable";
import { createTheme, Theme } from "@mui/material/styles";
import { getlocalStorageIdHotsite } from "../../util/LocalStorage";

let idHotsiteLocalStorage = getlocalStorageIdHotsite();

export const variables: IVariable = {
  IdHotsite: idHotsiteLocalStorage,
  Loja: "levycam",
  UserStorage: "HDgXltz14k2ovqv21NMXBA==",
  UserToken: "2sUTPiC0U0S/BtDAzODG9A==",
  UrlApi: "https://dev-ecommerce-lvc-api.primecase.com.br/api",
  UrlLoja:"https://dev-ecommerce-lvc-web.primecase.com.br"
};

export const template: Theme = createTheme({
  palette: {
    primary: {
      main: "#00428c",
      light: "#B799F2",
      //lighter: "#ece6f7",
      dark: "#032d5d",
    },
    secondary: {
      main: "#355c88",
    },
    error: {
      main: "#a91c1c",
    },
    text: {
      primary: "#222",
      //light: "#fff",
    },
    warning: {
      main: "#ff9800",
    },
    // white: {
    //   main: "#fff",
    // },
  },

  typography: {
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontSize: 14,
    },
  },
});
