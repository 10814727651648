import { useState, useEffect } from "react";
import CampoCheck from "../../Campos/CampoCheck";
import { getEnderecoCadastro } from "../../../api/Cadastro";
import useAlert from "../../../hooks/useAlert";
import useStyle from "./styles";
import { Skeleton, Box } from "@mui/material";
import Subtitulo from "../../Textos/Subtitulo";
import EnderecoNacional from "./EnderecoNacional";
import EnderecoExterior from "./EnderecoExterior";

export default function EnderecoPrincipal({
  user,
  setData,
  isLoading,
  enderecoDefaultValue = null,
  habilitarEnderecoExterior = false,
}) {
  const { showAlertError } = useAlert();

  const enderecoDefault = {
    bairro: enderecoDefaultValue?.bairro ?? "",
    cep: enderecoDefaultValue?.cep ?? "",
    cidade: enderecoDefaultValue?.cidade ?? "",
    complemento: enderecoDefaultValue?.complemento ?? "",
    logradouro: enderecoDefaultValue?.logradouro ?? "",
    numero: enderecoDefaultValue?.numero ?? "",
    idCliente: user !== null ? user.id : 0,
    idPais: enderecoDefaultValue?.idPais ?? 0,
    idUf: enderecoDefaultValue?.idUf ?? 0,
    ufDescricao: enderecoDefaultValue?.ufDescricao ?? "",
    id: enderecoDefaultValue?.id ?? 0,
    paises: enderecoDefaultValue?.paises,
  };

  const [enderecoState, setEnderecoState] = useState(enderecoDefault);
  const [cepChanged, setCepChanged] = useState(false);

  const classes = useStyle();

  const init = async () => {
    try {
      if (user) {
        const response = await getEnderecoCadastro(user.id);

        if (response.errorMessage && response.errorMessage.length > 0) {
          throw new Error(response.errorMessage);
        }

        if (response.data && response.data.id > 0) {
          let enderecoNovo = enderecoDefault;
          enderecoNovo.id = response.data.id;
          enderecoNovo.cep = response.data.cep;
          enderecoNovo.cidade = response.data.cidade;
          enderecoNovo.bairro = response.data.bairro;
          enderecoNovo.logradouro = response.data.logradouro;
          enderecoNovo.numero = response.data.numero;
          enderecoNovo.complemento = response.data.complemento;
          enderecoNovo.idUf = response.data.idUf;
          enderecoNovo.ufDescricao = response.data.ufDescricao;
          enderecoNovo.idPais = response.data.idPais;
          enderecoNovo.paises = response.data.paises;

          setData((prevState) => ({ ...enderecoNovo }));
        } else if (response.data) {
          let enderecoNovo = enderecoDefault;
          enderecoNovo.paises = response.data.paises;

          setData((prevState) => ({ ...enderecoNovo }));
        }
      }
    } catch (error) {
      showAlertError(error.message);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const handlerChangeEndereco = (enderecoNew) => {
    setEnderecoState(enderecoNew);
    setData(enderecoNew);
  };

  const handleChangeCheck = (e) => {
    let enderecoNovo = enderecoState;
    enderecoNovo.cep = "";
    enderecoNovo.cidade = "";
    enderecoNovo.bairro = "";
    enderecoNovo.logradouro = "";
    enderecoNovo.numero = "";
    enderecoNovo.complemento = "";
    enderecoNovo.idUf = 0;
    enderecoNovo.ufDescricao = "";
    enderecoNovo.idPais = 0;
    enderecoNovo.domiciliadoExterior = e.target.checked;

    setData((prevState) => ({ ...enderecoNovo }));
  };

  return (
    <>
      <div className={classes.box}>
        {isLoading ? (
          <Skeleton animation="wave" height={40} width={"20%"} />
        ) : (
          <>
            {habilitarEnderecoExterior && (
              <div>
                <Subtitulo sx={{ mb: 2, fontSize: 15, color: "#000" }}>
                  Cadastre o endereço
                </Subtitulo>
                <Box sx={{ mb: 2 }}>
                  <CampoCheck
                    key="check-endereco"
                    enable={true}
                    variant="filled"
                    size="medium"
                    sx={{ mb: 4 }}
                    fullWidth={false}
                    label="Domiciliado Exterior"
                    descricao=""
                    name="domiciliadoExterior"
                    value={enderecoState.domiciliadoExterior}
                    handleChangeCheck={handleChangeCheck}
                  />
                </Box>
              </div>
            )}
          </>
        )}
        {habilitarEnderecoExterior && enderecoState.domiciliadoExterior ? (
          <EnderecoExterior
            enderecoDefaultValue={enderecoState}
            isLoading={isLoading}
            setData={handlerChangeEndereco}
          />
        ) : (
          <EnderecoNacional
            enderecoDefaultValue={enderecoState}
            isLoading={isLoading}
            setData={handlerChangeEndereco}
            cepSelected={cepChanged}
            onChangeCep={setCepChanged}
          />
        )}
      </div>
    </>
  );
}
