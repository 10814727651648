import { IVariable } from "../../interfaces/IVariable";
import { createTheme, Theme } from "@mui/material/styles";
import { darkenColor } from "../../util/helpers";
import { getlocalStorageIdHotsite } from "../../util/LocalStorage";

let idHotsiteLocalStorage = getlocalStorageIdHotsite();

export const variables: IVariable = {
  IdHotsite: idHotsiteLocalStorage > 0 ? idHotsiteLocalStorage : 1047,
  Loja: "Indikey",
  UserStorage: "OQh2zd9R0Eqz3E5GndubHQ==",
  UserToken: "VVeyHI5i/U6DjfMXfE9N/w==",
  UrlApi: "https://prd-ecommerce-duo-api.primecase.com.br/api",
  UrlLoja:"https://prd-ecommerce-idk-web.primecase.com.br/",
};

export const template: Theme = createTheme({
  palette: {
    primary: {
      main: "#755a06",
      light: "#755a06",
      //lighter: "#ece6f7",
      dark: darkenColor("#755a06", 10), // Escurece em 20%
    },
    secondary: {
      main: "#c29f28",
    },
    error: {
      main: "#a91c1c",
    },
    text: {
      primary: "#222",
      //light: "#fff",
    },
    warning: {
      main: "#ff9800",
    },
    // white: {
    //   main: "#fff",
    // },
  },

  typography: {
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontSize: 14,
    },
  },
});
