import { IVariable } from "../../interfaces/IVariable";
import { createTheme, Theme } from "@mui/material/styles";
import { darkenColor } from "../../util/helpers";
import { getlocalStorageIdHotsite } from "../../util/LocalStorage";

let idHotsiteLocalStorage = getlocalStorageIdHotsite();

export const variables: IVariable = {
  IdHotsite: idHotsiteLocalStorage > 0 ? idHotsiteLocalStorage : 1046,
  Loja: "Cotar Câmbio",
  UserStorage: "26ab257accf8b1e023edd199e6c9d5af==",
  UserToken: "3e1464e612dc99a72b92cae20a844211==",
  UrlApi: "https://prd-ecommerce-duo-api.primecase.com.br/api",
  UrlLoja:"https://prd-ecommerce-ctc-web.primecase.com.br/",
};

export const template: Theme = createTheme({
  palette: {
    primary: {
      main: "#b10000",
      light: "#b10000",
      //lighter: "#ece6f7",
      dark: darkenColor("#b10000", 10), // Escurece em 20%
    },
    secondary: {
      main: "#171717",
    },
    error: {
      main: "#a91c1c",
    },
    text: {
      primary: "#222",
      //light: "#fff",
    },
    warning: {
      main: "#ff9800",
    },
    // white: {
    //   main: "#fff",
    // },
  },

  typography: {
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontSize: 14,
    },
  },
});
