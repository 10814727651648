import api from "../";
import { configurationVariables } from "../../environment";
import { sendDataToAPI } from "../../util/encryptApi";

const idHotsite = parseInt(configurationVariables.IdHotsite);
const idOrigem = 3; //LojaCambio;

export const getByIdCliente = async (idCliente) => {
  try {
    const { data: response } = await api.get(
      `/cliente/Cadastro/GetById?idCliente=${idCliente}&idOrigem=${idOrigem}`
    );

    return {
      data: response?.data,
      errorMessage: response?.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " RF637");
  }
};

export const getInit = async (idCliente, idPerfilLimiteOperacional) => {
  try {
    const { data: response } = await api.get(
      `/Cliente/Cadastro/Start?idCliente=${idCliente}&perfilLimiteOperacional=${idPerfilLimiteOperacional}&idOrigem=${idOrigem}`
    );

    return {
      data: response?.data,
      errorMessage: response?.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " RF1694");
  }
};

export const getDadosBasicos = async (idCliente, idPerfilLimiteOperacional) => {
  try {
    const { data: response } = await api.get(
      //`/cliente/Cadastro/DadosCliente?idCliente=${idCliente}&idPerfilLimiteOperacional=${idPerfilLimiteOperacional}&idOrigem=${idOrigem}`
      `/cliente/Cadastro/GetCamposCliente?idCliente=${idCliente}&idPerfilLimiteOperacional=${idPerfilLimiteOperacional}&idOrigem=${idOrigem}`
    );
    return response.data;
  } catch (error) {
    throw new Error(error + " RB119");
  }
};

export const getParticipacaoJuridica = async (
  idCliente,
  idPerfilLimiteOperacional
) => {
  try {
    const { data: response } = await api.get(
      `/Cliente/Cadastro/ParticipacaoJuridica?idCliente=${idCliente}&idPerfilLimiteOperacional=${idPerfilLimiteOperacional}&idOrigem=${idOrigem}`
    );

    return response.data;
  } catch (error) {
    throw new Error(error + " R4CFB");
  }
};

export const getDadoBancario = async (idCliente, idPerfilLimiteOperacional) => {
  try {
    const { data: response } = await api.get(
      `/Cliente/Cadastro/DadoBancario?idCliente=${idCliente}&idPerfilLimiteOperacional=${idPerfilLimiteOperacional}&idOrigem=${idOrigem}`
    );

    return response.data;
  } catch (error) {
    throw new Error(error + " RB6D9");
  }
};

export const getParticipacaoExterna = async (
  idCliente,
  idPerfilLimiteOperacional
) => {
  try {
    const { data: response } = await api.get(
      `/Cliente/Cadastro/ParticipacaoExterna?idCliente=${idCliente}&idPerfilLimiteOperacional=${idPerfilLimiteOperacional}&idOrigem=${idOrigem}`
    );

    return response.data;
  } catch (error) {
    throw new Error(error + " RA87E");
  }
};

export const getRepresentante = async (
  idCliente,
  idPerfilLimiteOperacional
) => {
  try {
    const { data: response } = await api.get(
      `/Cliente/Cadastro/Representante?idCliente=${idCliente}&idPerfilLimiteOperacional=${idPerfilLimiteOperacional}&idOrigem=${idOrigem}`
    );

    return response.data;
  } catch (error) {
    throw new Error(error + " R4169");
  }
};

export const getSocio = async (
  idCliente,
  idTipoEstadoCivil,
  idPerfilLimiteOperacional,
  idTipoSocio,
  idNacionalidade
) => {
  try {
    const { data: response } = await api.get(
      `/Cliente/Cadastro/Socio?idCliente=${idCliente}&idTipoEstadoCivil=${idTipoEstadoCivil}&idPerfilLimiteOperacional=${idPerfilLimiteOperacional}&idTipoSocio=${idTipoSocio}&idNacionalidade=${idNacionalidade}&idOrigem=${idOrigem}`
    );

    return response.data;
  } catch (error) {
    throw new Error(error + " RF613");
  }
};

export const editaSocio = async (
  idCliente,
  idTipoEstadoCivil,
  idPerfilLimiteOperacional,
  idTipoSocio,
  idNacionalidade,
  idSocio
) => {
  try {
    const { data: response } = await api.get(
      `/Cliente/Cadastro/EditaSocio?idCliente=${idCliente}&idTipoEstadoCivil=${idTipoEstadoCivil}&idPerfilLimiteOperacional=${idPerfilLimiteOperacional}&idTipoSocio=${idTipoSocio}&idNacionalidade=${idNacionalidade}&idSocio=${idSocio}&idOrigem=${idOrigem}`
    );

    return response.data;
  } catch (error) {
    throw new Error(error + " RF613");
  }
};

export const getQuestionarioFatca = async (
  idCliente,
  idPerfilLimiteOperacional
) => {
  try {
    const { data: response } = await api.get(
      `/Cliente/Cadastro/QuestionarioFatca?idCliente=${idCliente}&idPerfilLimiteOperacional=${idPerfilLimiteOperacional}&idOrigem=${idOrigem}`
    );

    return response.data;
  } catch (error) {
    throw new Error(error + " R5706");
  }
};

export const getEndereco = async (idCliente) => {
  try {
    const { data: response } = await api.post(
      `/Cliente/Cadastro/Endereco?idCliente=${idCliente}&idOrigem=${idOrigem}`
    );

    return response.data;
  } catch (error) {
    throw new Error(error + " RAB88");
  }
};

export const getDocumentos = async (idCliente, idPerfilLimiteOperacional) => {
  try {
    const { data: response } = await api.get(
      `/Cliente/Cadastro/Documentos?idCliente=${idCliente}&idPerfilLimiteOperacional=${idPerfilLimiteOperacional}&idOrigem=${idOrigem}`
    );

    return response.data;
  } catch (error) {
    throw new Error(error + " R5437");
  }
};

export const registrarPreCadastro = async (data) => {
  try {
    const { data: response } = await sendDataToAPI(
      `/Cadastro/v2/Registrar?idHotsite=${idHotsite}&idOrigem=${idOrigem}`,
      data
    );

    return {
      data: response.data,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " R5C6A");
  }
};

// export const registrarPreCadastro = async (data) => {
//   let data = {};
//   try {
//     const { data: response } = await api.post(
//       `/Cadastro/Registrar?idHotsite=${idHotsite}`,
//       data
//     );

//     return {
//       data: response.data,
//       errorMessage: response.errorMessage,
//     };
//   } catch (error) {
//     throw new Error(error + " R5C6A");
//   }
// };

/****************************/

export const saveDadosBasicos = async (
  cliente,
  idPerfilLimiteOperacional,
  idCliente,
  finish
) => {
  try {
    const { status, data: response } = await api.post(
      //`/Cliente/Cadastro/SalvarDadosCliente?idPerfilLimiteOperacional=${idPerfilLimiteOperacional}&finish=${finish}&idHotsite=${idHotsite}&idOrigem=${idOrigem}`,
      `/Cliente/Cadastro/SalvarCamposCliente?idPerfilLimiteOperacional=${idPerfilLimiteOperacional}&idCliente=${idCliente}&finish=${finish}&idHotsite=${idHotsite}&idOrigem=${idOrigem}`,
      cliente
    );

    return {
      status,
      data: response.data,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " R8812");
  }
};

export const saveEnderecoCadastro = async (
  endereco,
  idPerfilLimiteOperacional,
  finish
) => {
  try {
    const { status, data: response } = await api.post(
      `/Cliente/Cadastro/SalvarEndereco?idPerfilLimiteOperacional=${idPerfilLimiteOperacional}&finish=${finish}&idOrigem=${idOrigem}`,
      endereco
    );

    return {
      status,
      data: response.data,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " RB89F");
  }
};

export const getEnderecoCadastro = async (idCliente) => {
  try {
    const { status, data: response } = await api.get(
      `/Cliente/Cadastro/GetEndereco?idCliente=${idCliente}&idOrigem=${idOrigem}`
    );

    return {
      status,
      data: response.data,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " R53DB");
  }
};

export const saveParticipacaoJuridica = async (
  participacao,
  idPerfilLimiteOperacional,
  finish
) => {
  try {
    const { status, data: response } = await api.post(
      `/Cliente/Cadastro/SalvarParticipacaoJuridica?idPerfilLimiteOperacional=${idPerfilLimiteOperacional}&finish=${finish}&idOrigem=${idOrigem}`,
      participacao
    );

    return {
      status,
      data: response.data,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " R1134");
  }
};

export const saveDadoBancario = async (
  dadoBancario,
  idPerfilLimiteOperacional,
  finish
) => {
  try {
    const { status, data: response } = await api.post(
      `/Cliente/Cadastro/SalvarDadoBancario?idPerfilLimiteOperacional=${idPerfilLimiteOperacional}&finish=${finish}&idOrigem=${idOrigem}`,
      dadoBancario
    );

    return {
      status,
      data: response.data,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " R9FF2");
  }
};

export const saveDocumentos = async (
  documentos,
  idPerfilLimiteOperacional,
  finish
) => {
  try {
    const { status, data: response } = await api.post(
      `/Cliente/Cadastro/SalvarDocumentos?idPerfilLimiteOperacional=${idPerfilLimiteOperacional}&finish=${finish}&idHotsite=${idHotsite}&idOrigem=${idOrigem}`,
      documentos
    );

    return {
      status,
      data: response.data,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " R027D");
  }
};

export const saveParticipacaoExterna = async (
  participacao,
  idPerfilLimiteOperacional,
  finish
) => {
  try {
    const { status, data: response } = await api.post(
      `/Cliente/Cadastro/SalvarParticipacaoExterna?idPerfilLimiteOperacional=${idPerfilLimiteOperacional}&finish=${finish}&idOrigem=${idOrigem}`,
      participacao
    );

    return {
      status: status ?? null,
      data: response.data,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " RE5C2");
  }
};

export const saveRepresentante = async (
  representante,
  idPerfilLimiteOperacional,
  finish
) => {
  try {
    const { status, data: response } = await api.post(
      `/Cliente/Cadastro/SalvarRepresentante?idPerfilLimiteOperacional=${idPerfilLimiteOperacional}&finish=${finish}&idOrigem=${idOrigem}`,
      representante
    );

    return {
      status,
      data: response.data,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " RB5A3");
  }
};

export const saveSocio = async (
  socio,
  idPerfilLimiteOperacional,
  finish,
  idCliente
) => {
  try {
    const { status, data: response } = await api.post(
      `/Cliente/Cadastro/SalvarSocio?idPerfilLimiteOperacional=${idPerfilLimiteOperacional}&finish=${finish}&idCliente=${idCliente}&idOrigem=${idOrigem}`,
      socio
    );

    return {
      status,
      data: response.data,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " R40C3");
  }
};

export const saveQuestionarioFatca = async (
  questionarioFatca,
  idPerfilLimiteOperacional,
  finish
) => {
  try {
    const { status, data: response } = await api.post(
      `/Cliente/Cadastro/SalvarQuestionarioFatca?idPerfilLimiteOperacional=${idPerfilLimiteOperacional}&finish=${finish}&idOrigem=${idOrigem}`,
      questionarioFatca
    );

    return {
      status,
      data: response.data,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " R626E");
  }
};

export const changeDadosPessoaFisica = async (
  campos,
  idTipoEstadoCivil,
  idCliente
) => {
  try {
    const { status, data: response } = await sendDataToAPI(
      `/Cadastro/v2/ChangeDadosPessoaFisica?idOrigem=${idOrigem}`,
      {
        campos,
        idTipoEstadoCivil,
        idCliente,
      }
    );

    return {
      status,
      data: response.data,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " R14B0");
  }
};
