
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { toast } from "sonner";
import { decodeHTMLEntities } from "../../util/helpers"

const useAlert = () => {
  const theme = useTheme();
  let text = ""

  return {
    Alert: <></>,
    showAlertSuccess: (content) => {
      if (!!content) {
        text = decodeHTMLEntities(content);
        toast(
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '.2rem' }} >
            <CheckCircleIcon style={{ fontSize: '1.2rem' }} />{content}
          </Box>, {

          style: {
            background: theme.palette.success.main,
            color: theme.palette.success.contrastText,
          }
        })
      }
    },

    showAlertError: (content) => {
      if (!!content) {
        text = decodeHTMLEntities(content);
        toast(
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '.2rem' }} >
            <CancelIcon style={{ fontSize: '1.2rem' }} />{text}
          </Box>,
          {
            duration: 5500,
            style: {
              background: theme.palette.error.main,
              color: theme.palette.error.contrastText,
            }
          })
      }
    },
    showAlertInfo: (content) => {
      if (!!content) {
        text = decodeHTMLEntities(content);
        toast(
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '.2rem' }} >
            <InfoIcon style={{ fontSize: '1.2rem' }} />{text}
          </Box>, {
          style: {
            background: theme.palette.info.main,
            color: theme.palette.info.contrastText,
          }
        })
      }
    },
    showAlertWarning: (content) => {
      if (!!content) {
        text = decodeHTMLEntities(content);
        // toast.warning(content);
        toast(
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '.2rem' }} >
            <WarningIcon style={{ fontSize: '1.2rem' }} />{text}
          </Box>, {
          style: {
            background: theme.palette.warning.main,
            color: theme.palette.warning.contrastText,
          }
        })
      }
    },
  };
};

export default useAlert;
